import Q from 'q';
import trains_product_getOrderInsuranceInfo from '@/lib/data-service/trains/trains_product_getOrderInsuranceInfo'
import trains_common_getOrderBaseData from '@/lib/data-service/trains/trains_common_getOrderBaseData'
import trains_product_getAlternativeTrainNumber from '@/lib/data-service/trains/trains_product_getAlternativeTrainNumber'
import trains_order_selectOrderAmountOfCalculation from '@/lib/data-service/trains/trains_order_selectOrderAmountOfCalculation'
import web_common_tourOrder_getTravelAccount from '@/lib/data-service/default/web_common_tourOrder_getTravelAccount' // 获取旅游经理的信息
import trains_order_OrderToSnapUp from '@/lib/data-service/trains/trains_order_OrderToSnapUp'
import moment from 'moment'
export default {
  data () {
    const validateCard = (rule, value, callback) => {
      let cardType = 1
      for (let i = 0, l = this.trainsOrderPassengers.length; i < l; i++) {
        if (this.trainsOrderPassengers[i].passengerCardNumber === value) {
          cardType = this.trainsOrderPassengers[i].passengerCardType
        }
      }
      if (cardType === 1) {
        const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!regIdCard.test(value)) {
          callback(new Error('身份证号填写有误'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    };
    const validatePhone = (rule, value, callback) => {
      const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('不符合手机号码格式'))
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback()
        return
      }
      const regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('邮件格式不正确'))
      }
    };
    return {
      fromStationCode: '',
      toStationCode: '',
      trainCode: '',
      trainDate: '',
      seatPrice: 0,

      alternativeTrainList: [],
      showMore: false,
      seatList: [],
      insuranceInfoVos: {
        insuranceProId: '',
      },
      orderBookingInfoVo: {
        // acceptNoSeat: 1,
        acceptSeatCodes: '', // 抢票备选坐席 "yw","rw","edz","gjrw","ydz"
        acceptTrainCodes: '', // 抢票备选车次 "G112","T110"
        adultCounts: 1,
        agentTicketPrice: 0,
        arriveDate: '',
        arriveTime: '',
        // backSeat: '',
        buyTicketsType: 0,

        buyerContactEmail: '',
        buyerContactName: '',
        buyerContactPhone: '',
        remark: '',

        childrenCounts: 0,
        // chooseSeat: '',
        // finalRobTicketsTime: '', // 抢票结束时间？？
        fromStationCode: '',
        fromStationName: '',
        // fromStationSn: '', // ??
        headCounts: 1,

        insuracneFlag: 0,
        insuranceAmount: 0,
        insuranceName: '',
        insurancePrice: 0,

        orderAmount: 0,
        orderNo: '',
        // orderSource: '' ??
        serviceChargeAmount: 0, // 服务费总价
        serviceChargePrice: 0, // 服务费单价
        robTicketsAmount: 0, // 抢票套餐总额
        robTicketsFee: 0, // 抢票套餐价格
        seatAmount: 0,
        seatCode: '',
        seatName: '',
        seatPrice: 0,
        startDate: '',
        startTime: '',
        toStationCode: '',
        toStationName: '',
        // toStationSn: '',
        trainInnerCode: '',
        trainNo: '',
        arriveDateAndTime: '',
        ptContactName: '',
        ptContactPhone: '',
        earliestDepartureTime: '',
        majorSeatPrice: ''
      },
      acceptSeatCodesArr: [],
      trainsOrderPassengers: [
        {
          passengerBirthday: '',
          passengerCardNumber: '',
          passengerCardType: 1,
          passengerCountry: '',
          passengerExpireDate: '',
          passengerName: '',
          passengerSeq: '',
          passengerSex: 1,
          passengerType: 1,
          passengerIndex: '',
          userType: 1, // 1是大人， 2是小孩
        }
      ],
      spanTime: '',
      sexList: [],
      cardType: [],
      nationalities: [], // 国籍列表
      maxNum: 5,
      passengerRule: {
        passengerName: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        passengerCardNumber: [
          {required: true, message: '请输入证件号码', trigger: 'blur'},
          { validator: validateCard, trigger: 'blur' }
        ],
        passengerSex: [
          {required: true, message: '请选择性别', trigger: 'change'},
        ],
        passengerCountry: [
          {required: true, message: '请选择国籍', trigger: 'change'},
        ],
        passengerBirthday: [
          {required: true, message: '请选择出生日期', trigger: 'change'},
        ],
        passengerExpireDate: [
          {required: true, message: '请选择证件有效期截止日期', trigger: 'change'},
        ]
      },
      insuranceProId: '',
      insuranceList: [],
      insuranceDesc: '',
      contactRule: {
        buyerContactName: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'},
        ],
        buyerContactPhone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          { validator: validatePhone, trigger: 'blur' }
        ],
        buyerContactEmail: [
          { validator: validateEmail, trigger: 'blur' }
        ]
      },
      checkedRead: false,
      procurementRuleShow: false,
      procurementRuleText: '',

      productListShow: false,
      dialogActiveList: [], // 车次弹窗中选中的车次列表
      dialogOriActiveList: [], // 车次弹窗中选中的车次列表（原始数据，用于取消时消除勾选状态）
      searchFormStationCode: '',
      searchToStationCode: '',
      showRuleMore: false,
    }
  },
  components: {},
  created () {
    document.documentElement.scrollTop = 0;
    this.orderBookingInfoVo.fromStationCode = this.$route.query.fromStationCode
    this.orderBookingInfoVo.fromStationName = this.$route.query.fromStationName
    // this.orderBookingInfoVo.fromStationType = this.$route.query.fromStationType // 无
    this.orderBookingInfoVo.fromTime = this.$route.query.fromTime
    this.orderBookingInfoVo.toStationCode = this.$route.query.toStationCode
    this.orderBookingInfoVo.toStationName = this.$route.query.toStationName
    // this.orderBookingInfoVo.toStationType = this.$route.query.toStationType
    this.orderBookingInfoVo.trainNo = this.$route.query.trainNo
    this.orderBookingInfoVo.trainCode = this.$route.query.trainCode
    this.orderBookingInfoVo.toTime = this.$route.query.toTime
    this.orderBookingInfoVo.trainStartDate = this.$route.query.trainStartDate
    if (this.$route.query.isSale === '1') {
      this.orderBookingInfoVo.buyTicketsType = 4
    } else {
      this.orderBookingInfoVo.buyTicketsType = 5
    }

    this.orderBookingInfoVo.arriveDate = moment(this.$route.query.arriveDate).format('YYYY-MM-DD')
    this.orderBookingInfoVo.arriveTime = moment(this.$route.query.arriveDate).format('HH:mm')
    this.orderBookingInfoVo.startDate = this.$route.query.fromDate
    this.orderBookingInfoVo.startTime = this.$route.query.fromTime
    this.orderBookingInfoVo.arriveDateAndTime = this.$route.query.arriveDate
    // backSeat
    // orderNo
    // orderSource

    this.orderBookingInfoVo.serviceChargePrice = parseFloat(this.$route.query.serviceCharge)
    this.orderBookingInfoVo.robTicketsFee = parseFloat(this.$route.query.expressFee)

    this.seatPrice = parseFloat(this.$route.query.seatPrice) // 保存一份抢票坐席价格
    this.orderBookingInfoVo.seatPrice = parseFloat(this.$route.query.seatPrice)
    this.orderBookingInfoVo.majorSeatPrice = parseFloat(this.$route.query.seatPrice)
    this.orderBookingInfoVo.seatCode = this.$route.query.seatCode
    this.orderBookingInfoVo.seatName = this.$route.query.seatName
    this.orderBookingInfoVo.canChooseSeat = parseInt(this.$route.query.canChooseSeat) === 1 ? true : false
    // this.orderBookingInfoVo.numberOfSeats = parseInt(this.$route.query.numberOfSeats)  // 在线选座每排座位数
    this.spanTime = this.$route.query.spanTime

    this.searchFormStationCode = this.$route.query.searchFormStationCode
    this.searchToStationCode = this.$route.query.searchToStationCode

    // 初始化备选坐席列表
    /*let seatItem = {
      seatCode: this.orderBookingInfoVo.seatCode,
      seatName: this.orderBookingInfoVo.seatName,
      seatPrice: this.seatPrice
    } */
    this.seatList = JSON.parse(this.$route.query.seatList)

    this.orderBookingAmountCalculation()
  },
  mounted () {
    this.getInsuranceInfo()
    this.getOrderBaseData()
    this.getAlternativeTrainNumber()
    this.getTravelAccount()
    this.scrollToTop()
    window.addEventListener('scroll', this.scrollToTop)
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  watch: {
    trainsOrderPassengers(val) {
      this.orderBookingAmountCalculation()
    }
  },
  computed: {},
  filters: {
    trainStartFilter(val) {
      return moment(val).format('YYYY年MM月DD日')
    }
  },
  methods: {
    toHome() {
      this.$router.push({
        name: 'distributor-train-front-home'
      })
    },
    scrollToTop() {
      let leftBox = this.$refs.leftBox
      let rightBox = this.$refs.rightBox
      let top = leftBox.getBoundingClientRect().top
      let leftBoxHeight = leftBox.clientHeight
      let rightBoxHeight = rightBox.clientHeight
      if (leftBoxHeight > rightBoxHeight && top <= 0) {
        rightBox.style.position = 'absolute'
        rightBox.style.right = 0
        if (leftBoxHeight < Math.abs(top) + rightBoxHeight) {
          rightBox.style.top = (leftBoxHeight - rightBoxHeight) + 'px'
        } else {
          rightBox.style.top = parseInt(Math.abs(top)) + 'px'
        }
      } else {
        rightBox.style.position = 'relative'
        rightBox.style.top = 0
      }
    },
    showMoreExplain() {
      let showMore = this.showMore
      this.showMore = !showMore
      if (!showMore) {
        window.scrollTo(0, document.documentElement.scrollTop - 10)
      }
    },
    showRuleMoreExplain() {
      let showMore = this.showRuleMore
      this.showRuleMore = !showMore
      if (!showMore) {
        window.scrollTo(0, document.documentElement.scrollTop - 10)
      }
    },
    getTravelAccount() {
      web_common_tourOrder_getTravelAccount().then(res => {
        this.orderBookingInfoVo.ptContactName = res.travelAccountManager
        this.orderBookingInfoVo.ptContactPhone = res.phone
      })
    },
    // 获取备选车次列表
    getAlternativeTrainNumber() {
      let params = {
        fromStationCode: this.orderBookingInfoVo.fromStationCode,
        toStationCode: this.orderBookingInfoVo.toStationCode,
        trainCode: this.orderBookingInfoVo.trainCode,
        trainDate: moment(this.orderBookingInfoVo.trainStartDate).format('YYYY-MM-DD')
      }
      trains_product_getAlternativeTrainNumber(params).then(res => {
        let alternativeTrainList = res.resultList
        alternativeTrainList.forEach(value => {
          value.active = false
          value.beforeType = ''
        })
        this.alternativeTrainList = alternativeTrainList
      })
      /*let seatList = []
      let seatResult = []
      trains_product_getAlternativeTrainNumber(params).then(res => {
        let alternativeTrainList = res.resultList
        alternativeTrainList.forEach(value => {
          value.active = false
          value.beforeType = ''
          /value.trainSeatVoList.forEach(val => {
            seatList.push(val)
          })
        })
        this.alternativeTrainList = alternativeTrainList

        // 获取备选车座列表
        for (let i = 0, l = seatList.length; i < l; i++) {
          let hasItem = false
          for (let m = 0, n = seatResult.length; m < n; m++) {
            if (seatResult[m].seatCode === seatList[i].seatCode) {
              hasItem = true
              break
            }
          }
          if (!hasItem) {
            seatResult.push(seatList[i])
          }
        }
        this.seatList = seatResult

        this.chooseSeat()
      })*/
    },
    getStationIcon(val) {
      if (val === 1) {
        return 'start-icon'
      } else if (val === 2) {
        return 'pass-by-icon'
      } else {
        return 'end-icon'
      }
    },
    handleShowMore() {
      // this.showMore = true
      this.productListShow = true
      // 遍历一次勾选状态
      this.$nextTick(() => {
        this.alternativeTrainList.forEach((value, index) => {
          if (index > 4) {
            this.$refs.multipleTable.toggleRowSelection(value, value.active)
          }
        })
      })
    },
    chooseTrain(val, index) {
      let alternativeTrainList = this.alternativeTrainList
      let active = alternativeTrainList[index].active
      let activeNum = 0
      for (let i = 0, l = alternativeTrainList.length; i < l; i++) {
        if (alternativeTrainList[i].active) {
          activeNum++
        }
      }
      if (activeNum >= 4 && !active) {
        this.$message('最多可以选择4个备选车次');
        return
      }
      alternativeTrainList[index].active = !active
      this.alternativeTrainList = alternativeTrainList
      if (index > 4) {
        this.$refs.multipleTable.toggleRowSelection(val)
      }

      this.filterSeatList()
    },
    filterSeatList() {
      let seatList = []
      let seatResult = []
      /*let seatItem = {
        seatCode: this.orderBookingInfoVo.seatCode,
        seatName: this.orderBookingInfoVo.seatName,
        seatPrice: this.seatPrice
      }
      seatList.push(seatItem)*/
      seatList = JSON.parse(this.$route.query.seatList)
      console.log(JSON.parse(this.$route.query.seatList))
      console.log(this.alternativeTrainList)
      this.alternativeTrainList.forEach(value => {
        if (value.active) {
          value.trainSeatVoList.forEach(val => {
            seatList.push(val)
          })
        }
      })

      // 获取备选车座列表
      console.log(seatList)
      for (let i = 0, l = seatList.length; i < l; i++) {
        let hasItem = false
        for (let m = 0, n = seatResult.length; m < n; m++) {
          if (seatResult[m].seatCode === seatList[i].seatCode) {
            hasItem = true
            // 比较价格
            if (parseFloat(seatResult[m].seatPrice) < parseFloat(seatList[i].seatPrice)) {
              seatResult[m].seatPrice = seatList[i].seatPrice
            }
            break
          }
        }
        if (!hasItem) {
          seatResult.push(seatList[i])
        }
      }
      console.log(seatResult)
      this.seatList = seatResult

      // 过滤选中的项
      let acceptSeatCodesArr = []
      if (this.acceptSeatCodesArr.length > 0) {
        for (let i = 0, l = this.acceptSeatCodesArr.length; i < l; i++) {
          for (let m = 0, n = this.seatList.length; m < n; m++) {
            if (this.acceptSeatCodesArr[i] === this.seatList[m].seatCode) {
              acceptSeatCodesArr.push(this.acceptSeatCodesArr[i])
              break
            }
          }
        }
        this.acceptSeatCodesArr = acceptSeatCodesArr
      }

      this.chooseSeat(this.acceptSeatCodesArr)

    },
    delRow({row, rowIndex}) {
      if (rowIndex < 5) {
        return 'display-none'
      } else {
        return ''
      }
    },
    selectTr(selection, row) {
      // 获取当前已选择数
      let alternativeTrainList = this.alternativeTrainList
      let activeNum = 0
      for (let i = 0, l = alternativeTrainList.length; i < l; i++) {
        if (alternativeTrainList[i].active) {
          activeNum++
        }
      }
      let addNum = 0
      let delNum = 0
      this.dialogActiveList.forEach(value => {
        if (value.beforeType === 'submit') {
          addNum++
        } else {
          delNum++
        }
      })
      // 如果当前选择的数大于等于5，如果当前是已经选择过的对象，判断当前是否是勾选状态， 如果当前不是选择过的对象，判断是否在勾选状态
      // (activeNum + addNum - delNum) >= 5 && (row.active === true && ((row.beforeType === '' || row.beforeType === 'submit')) || (row.active === false && row.beforeType ==='submit'))
      // (activeNum + addNum - delNum) >= 5 && ((row.active === true &&row.beforeType === '') || row.beforeType === 'submit')
      if ((activeNum + addNum - delNum) >= 4 && ((row.active === true &&row.beforeType === '') || row.beforeType === 'submit')) {
        // 取消当前
        for (let i = 0, l = alternativeTrainList.length; i < l; i++) {
          if (alternativeTrainList[i].trainCode === row.trainCode) {
            // alternativeTrainList[i].active = false
            alternativeTrainList[i].beforeType = 'cancel'
            let item = Object.assign({}, row)
            item.beforeType = 'cancel'
            this.dialogActiveList.push(item)
            if (!row.active) {
              this.dialogOriActiveList.push(row)
            }
            break
          }
        }
        this.alternativeTrainList = alternativeTrainList
      } else if ((activeNum + addNum - delNum) < 4) {
        for (let i = 0, l = alternativeTrainList.length; i < l; i++) {
          if (alternativeTrainList[i].trainCode === row.trainCode) {
            // alternativeTrainList[i].active = !alternativeTrainList[i].active
            /*if (alternativeTrainList[i].active && (alternativeTrainList[i].beforeType === '' || alternativeTrainList[i].beforeType === 'submit')) {
              alternativeTrainList[i].beforeType = 'cancel'
              let item = Object.assign({}, row)
              item.beforeType = 'cancel'
              this.dialogActiveList.push(item)
            } else {
              alternativeTrainList[i].beforeType = 'submit'
              let item = Object.assign({}, row)
              item.beforeType = 'submit'
              this.dialogActiveList.push(item)
            }*/
            if (alternativeTrainList[i].active) {
              if (alternativeTrainList[i].beforeType === '' || alternativeTrainList[i].beforeType === 'submit') {
                alternativeTrainList[i].beforeType = 'cancel'
                let item = Object.assign({}, row)
                item.beforeType = 'cancel'
                this.dialogActiveList.push(item)
              } else {
                alternativeTrainList[i].beforeType = 'submit'
                let item = Object.assign({}, row)
                item.beforeType = 'submit'
                this.dialogActiveList.push(item)
              }
            } else {
              if (alternativeTrainList[i].beforeType === '' || alternativeTrainList[i].beforeType === 'cancel') {
                alternativeTrainList[i].beforeType = 'submit'
                let item = Object.assign({}, row)
                item.beforeType = 'submit'
                this.dialogActiveList.push(item)
                this.dialogOriActiveList.push(row)
              } else {
                alternativeTrainList[i].beforeType = 'cancel'
                let item = Object.assign({}, row)
                item.beforeType = 'cancel'
                this.dialogActiveList.push(item)
                this.dialogOriActiveList.push(row)
              }
            }
            break
          }
        }
        this.alternativeTrainList = alternativeTrainList
      } else {
        // 刚才勾选的取消掉
        this.$refs.multipleTable.toggleRowSelection(row)
        this.$message('最多可以选择4个备选车次');
      }
    },
    cancelChoose() {
      this.dialogOriActiveList.forEach(value => {
        this.$refs.multipleTable.toggleRowSelection(value)
      })
      this.productListShow = false
      this.dialogActiveList = []
      this.dialogOriActiveList = []
      this.alternativeTrainList.forEach(value => {
        value.beforeType = ''
      })
    },
    submitChoose() {
      this.productListShow = false
      this.dialogActiveList = []
      this.dialogOriActiveList = []
      this.alternativeTrainList.forEach(value => {
        if (value.beforeType === 'cancel') {
          value.active = false
        } else if (value.beforeType === 'submit') {
          value.active = true
        }
        value.beforeType = ''
      })

      this.filterSeatList()
    },
    chooseSeat(val) {
      // 比较最大价格
      let price = 0
      // let maxPrice = 0

      if (val !== undefined && val.length > 0) {
        // 有选中情况
        for (let i = 0, l = val.length; i < l; i++) {
          for (let m = 0, n = this.seatList.length; m < n; m++) {
            if (val[i] === this.seatList[m].seatCode && this.seatList[m].seatPrice > price) {
              price = parseFloat(this.seatList[m].seatPrice)
              break
            }
          }
        }
      }/* else {
        this.seatList.forEach(value => {
          if (parseInt(value.seatPrice) > maxPrice) {
            maxPrice = parseInt(value.seatPrice)
          }
        })
      }*/
      // let seatPrice = (val !== undefined && val.length > 0) ? price : maxPrice
      let seatPrice = price
      this.orderBookingInfoVo.seatPrice = seatPrice > this.seatPrice ? seatPrice : this.seatPrice

      this.orderBookingAmountCalculation()
    },
    delThisUser(val, index) {
      let adult = 0
      let children = 0
      for (let i = 0, l = this.trainsOrderPassengers.length; i < l; i++) {
        if (this.trainsOrderPassengers[i].userType === 1) {
          adult++
        } else {
          children++
        }
      }
      if (val.userType === 1 && adult === 1) {
        this.$message({
          message: '必须至少含有一位成人乘客',
          type: 'warning'
        })
        return
      }
      this.trainsOrderPassengers.splice(index, 1)
      this.userSort()
      if (val.passengerType === 2) {
        this.orderBookingInfoVo.childrenCounts--
      } else {
        this.orderBookingInfoVo.adultCounts--
      }
      this.orderBookingInfoVo.headCounts--

      // 如果座位有选多了，则去掉一个座位
      /*if (this.trainsOrderPassengers.length < this.seatList.length) {
        let sIndex = this.seatList.length - 1
        let value = this.seatList[sIndex]
        this.seatList.splice(sIndex, 1)
        for (let i = 0, l = this.seatArr.length; i < l; i++) {
          let seatItem = this.seatArr[i]
          for (let m = 0, n = seatItem.length; m < n; m++) {
            if (seatItem[m].value === value) {
              this.seatArr[i][m].active = false
              break
            }
          }
        }
      }*/
    },
    clearThisUser(val, index) {
      let params = {}
      if (val.passengerType === 1) {
        params = {
          orderNo: '',
          passengerBirthday: '',
          passengerCardNumber: '', // 证件号
          passengerCardType: 1,
          passengerCountry: '', //国籍
          passengerExpireDate: '', // 证件有效期
          passengerName: '', // 乘客姓名
          passengerSeq: '', // 顺序码
          passengerType: 1, // 乘客类型
          passengerSex: 1, // 性别
          passengerIndex: '', // 大人 1+index  小孩 2+index
          ticketStatus: '',
          userType: 1, // 1是大人， 2是小孩
        }
      } else {
        params = {
          orderNo: '',
          passengerBirthday: '',
          passengerCardNumber: '', // 证件号
          passengerCardType: 1,
          passengerCountry: '', //国籍
          passengerExpireDate: '', // 证件有效期
          passengerName: '', // 乘客姓名
          passengerSeq: '', // 顺序码
          passengerType: 2, // 乘客类型
          passengerSex: 1, // 性别
          passengerIndex: '', // 大人 1+index  小孩 2+index
          userType: 2, // 1是大人， 2是小孩
          ticketStatus: '',
        }
      }
      this.trainsOrderPassengers.splice(index, 1, params)
      this.userSort()
    },
    addAdult() {
      if (this.trainsOrderPassengers.length >= this.maxNum) {
        return
      }
      this.trainsOrderPassengers.push({
        passengerBirthday: '',
        passengerCardNumber: '', // 证件号
        passengerCardType: 1,
        passengerCountry: '', //国籍
        passengerExpireDate: '', // 证件有效期
        passengerName: '', // 乘客姓名
        passengerSeq: '', // 顺序码
        passengerType: 1, // 乘客类型
        passengerSex: 1, // 性别
        passengerIndex: '', // 大人 1+index  小孩 2+index
        userType: 1, // 1是大人， 2是小孩
      })
      this.userSort()
      this.orderBookingInfoVo.adultCounts++
      this.orderBookingInfoVo.headCounts++
    },
    addChild() {
      if (this.trainsOrderPassengers.length >= this.maxNum) {
        return
      }
      this.trainsOrderPassengers.push({
        passengerBirthday: '',
        passengerCardNumber: '', // 证件号
        passengerCardType: 1,
        passengerCountry: '', //国籍
        passengerExpireDate: '', // 证件有效期
        passengerName: '', // 乘客姓名
        passengerSeq: '', // 顺序码
        passengerType: 2, // 乘客类型
        passengerSex: 1, // 性别
        passengerIndex: '', // 大人 1+index  小孩 2+index
        userType: 2, // 1是大人， 2是小孩
      })
      this.userSort()
      this.orderBookingInfoVo.childrenCounts++
      this.orderBookingInfoVo.headCounts++
    },
    userSort() {
      let adultNum = 0;
      let childNum = 0;
      this.trainsOrderPassengers.forEach(value => {
        if (value.passengerType === 1) {
          value.passengerIndex = '1' + adultNum
          adultNum++
        } else {
          value.passengerIndex = '2' + childNum
          childNum++
        }
      })
    },
    getOrderBaseData() {
      Q.when().then(() => {
        return trains_common_getOrderBaseData()
      }).then(res => {
        this.sexList = res.genderTypeMapList
        let cardType = res.passengerCardTypeList
        let childCardIndex = 0
        for (let i = 0, l = cardType.length; i < l; i++) {
          if (cardType[i].code === 2) {
            childCardIndex = i
            break
          }
        }
        cardType.splice(childCardIndex, 1)
        this.cardType = cardType
        this.nationalities = res.nationalities
      })
    },
    getInsuranceInfo() {
      Q.when().then(() => {
        return trains_product_getOrderInsuranceInfo()
      }).then((res) => {
        let insuranceList = res.data
        insuranceList.forEach((value, index) => {
          value.label = `${value.insurancePrice}元/份 ${value.insuranceRemark}`
          if (index === 0) {
            this.insuranceProId = value.id
            this.insuranceDesc = value.insuranceDesc
          }
        })
        this.insuranceList = res.data
      })
    },
    changeInsuranceDesc(val) {
      for (let i = 0, l = this.insuranceList.length; i < l; i++) {
        if (val === this.insuranceList[i].id) {
          this.insuranceDesc = this.insuranceList[i].insuranceClass.desc

          // 如果当前是选定购买保险的情况
          if (this.orderBookingInfoVo.insuracneFlag === 1) {
            this.insuranceInfoVos.insuranceProId = val
            this.orderBookingInfoVo.insurancePrice = this.insuranceList[i].insurancePrice // 单价
            this.orderBookingInfoVo.insuranceName = this.insuranceList[i].insuranceClass.name
            this.orderBookingAmountCalculation()
          }
          break
        }
      }
    },
    addInsurance() {
      if (this.insuranceInfoVos.insuranceProId === '') {
        if (this.insuranceProId === '') {
          return
        }
        this.orderBookingInfoVo.insuracneFlag = 1
        for (let i = 0, l = this.insuranceList.length; i < l; i++) {
          if (this.insuranceProId === this.insuranceList[i].id) {
            this.insuranceInfoVos.insuranceProId = this.insuranceProId
            this.orderBookingInfoVo.insurancePrice = this.insuranceList[i].insurancePrice // 单价
            this.orderBookingInfoVo.insuranceName = this.insuranceList[i].insuranceClass.name
            break
          }
        }
      } else {
        this.orderBookingInfoVo.insuracneFlag = 0
        this.insuranceInfoVos = {
          insuranceProId: ''
        }
        this.orderBookingInfoVo.insurancePrice = 0 // 单价
        this.orderBookingInfoVo.insuranceName = ''
      }
      // 计算
      this.orderBookingAmountCalculation()
    },
    orderBookingAmountCalculation() {
      let priceParams = {
        headCounts: this.orderBookingInfoVo.headCounts,
        insurancePrice: this.orderBookingInfoVo.insuracneFlag === 1 ? this.orderBookingInfoVo.insurancePrice : 0,
        seatPrice: this.orderBookingInfoVo.seatPrice,
        robTicketsFee: this.orderBookingInfoVo.robTicketsFee, // 抢票费， 不用robTicketsFee
        serviceChargePrice: this.orderBookingInfoVo.serviceChargePrice, // 服务费
      }
      trains_order_selectOrderAmountOfCalculation(priceParams).then(res => {
        this.orderBookingInfoVo.insuranceAmount = res.insuranceAmount
        this.orderBookingInfoVo.orderAmount = res.buyerPaymentTotalAmount
        this.orderBookingInfoVo.seatAmount = res.seatAmount
        this.orderBookingInfoVo.robTicketsAmount = res.robTicketsAmount
        this.orderBookingInfoVo.serviceChargeAmount = res.serviceChargeAmount
      })
    },
    back() {
      // this.$router.go(-1)
      this.$router.push({
        name: 'distributor-train-front-search',
        query: {
          fromStationCode: this.searchFormStationCode,
          toStationCode: this.searchToStationCode,
          time: moment(this.orderBookingInfoVo.trainStartDate).format('YYYY-MM-DD'),
        }
      })
    },
    toChooseTrain() {
      this.$router.push({
        name: 'distributor-train-front-search',
        query: {
          fromStationCode: this.orderBookingInfoVo.fromStationCode,
          toStationCode: this.orderBookingInfoVo.toStationCode,
          time: moment(this.orderBookingInfoVo.trainStartDate).format('YYYY-MM-DD'),
        }
      })
    },
    timeToMinute(val) {
      let timeArr = val.split(':')
      return parseInt(timeArr[0]) * 60 + parseInt(timeArr[1])
    },
    submit() {
      if (!this.checkedRead) {
        this.$message({
          message: '请先勾选并同意条款',
          type: 'warning'
        })
        return
      }
      // 判断是否有成人
      let isHaveAdult = false
      for (let i = 0, l = this.trainsOrderPassengers.length; i < l; i++) {
        if (this.trainsOrderPassengers[i].userType === 1) {
          isHaveAdult = true
          break
        }
      }
      if (!isHaveAdult) {
        this.$message({
          message: '必须至少含有一位成人乘客',
          type: 'warning'
        })
        return
      }
      let formList = []
      let formState = []
      this.trainsOrderPassengers.forEach((value, index) => {
        formList.push('form' + index)
        formState.push(-1)
      })
      formList.map((value, index) => {
        // 先循环判断$refs中是数组的表单
        this.$refs[value][0].validate((valid) => {
          valid ? formState[index] = 1 : formState[index] = -1
          if (formState.indexOf(-1) === -1) {
            // 最后判断contactForm表单
            this.$refs['contactForm'].validate((formValid) => {
              if (formValid) {
                // 录入备选车次
                let acceptTrainCodes = []
                let acceptTrain = []
                this.alternativeTrainList.forEach(value1 => {
                  if (value1.active) {
                    acceptTrainCodes.push(value1.trainCode)
                    acceptTrain.push(value1.fromTime)
                  }
                })
                if (acceptTrain.length === 0) {
                  this.orderBookingInfoVo.earliestDepartureTime = this.orderBookingInfoVo.fromTime
                } else {
                  let min = ''
                  acceptTrain.push(this.orderBookingInfoVo.fromTime)
                  for (let i = 0, l = acceptTrain.length; i < l; i++) {
                    if (i === 0) {
                      min = acceptTrain[i]
                      continue
                    }
                    let nowTrain = this.timeToMinute(acceptTrain[i])
                    let beforeTrain = this.timeToMinute(min)
                    if (nowTrain < beforeTrain) {
                      min = acceptTrain[i]
                    }
                  }
                  this.orderBookingInfoVo.earliestDepartureTime = min
                }
                this.orderBookingInfoVo.acceptTrainCodes = acceptTrainCodes.join(',')
                // 录入备选坐席
                this.orderBookingInfoVo.acceptSeatCodes = this.acceptSeatCodesArr.join(',')
                // 填入乘客顺序码 + 身份证类型的用户获取生日和性别
                this.trainsOrderPassengers.forEach((value1, index1) => {
                  value1.passengerSeq = index1 + 1
                  if (value1.passengerCardType === 1 && value1.userType === 1) {
                    value1.passengerBirthday = this.getBirth(value1.passengerCardNumber)
                    value1.passengerSex = this.getSex(value1.passengerCardNumber)
                  }
                })
                let params = {
                  insuranceInfoVos: this.insuranceInfoVos.insuranceProId === '' ? null : this.insuranceInfoVos,
                  orderBookingInfoVo: this.orderBookingInfoVo,
                  trainsOrderPassengers: this.trainsOrderPassengers
                }
                trains_order_OrderToSnapUp(params).then(res => {
                  let orderNo = res.orderNo
                  this.$router.push({
                    name: 'distributor-train-front-pay',
                    query: {
                      orderNo,
                      type: 'booking',
                    }
                  })
                })
              }
            })
          }
        })
      })
    },
    getBirth(idCard) {
      let birthday = '';
      if (idCard != null && idCard !== '') {
        if (idCard.length === 15) {
          birthday = '19' + idCard.slice(6,12);
        } else if (idCard.length === 18) {
          birthday = idCard.slice(6,14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/,'$1-$2-');
        //通过正则表达式来指定输出格式为:1990-01-01
      }
      return birthday;
    },
    getSex(idCard) {
      let sexStr = '';
      if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexStr = 1;
      } else {
        sexStr = 2;
      }
      return sexStr;
    },
  }
}

/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
