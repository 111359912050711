// trains_product_getAlternativeTrainNumber 备选车次查询

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_product_getAlternativeTrainNumber(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const fromStationCode = pParameter.fromStationCode || ''
  const toStationCode = pParameter.toStationCode || ''
  const trainCode = pParameter.trainCode || ''
  const trainDate = pParameter.trainDate || ''
  params.method = 'POST'
  params.urlSuffix = '/trains/product/getAlternativeTrainNumber'
  params.data = {
    fromStationCode,
    toStationCode,
    trainCode,
    trainDate
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
