// trains_order_selectOrderAmountOfCalculation 抢票订单金额计算

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_order_selectOrderAmountOfCalculation(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/trains/order/selectOrderAmountOfCalculation'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
