// trains_order_OrderToSnapUp 抢票预定

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_order_OrderToSnapUp(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const insuranceInfoVos = pParameter.insuranceInfoVos || {}
  const orderBookingInfoVo = pParameter.orderBookingInfoVo || {}
  const trainsOrderPassengers = pParameter.trainsOrderPassengers || []
  params.method = 'POST'
  params.urlSuffix = '/trains/order/OrderToSnapUp'
  params.data = {
    insuranceInfoVos,
    orderBookingInfoVo,
    trainsOrderPassengers
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
